#about section {
  margin: 0 auto;
  padding: 100px;
  justify-content: center;
}
#about h1 {
  text-align: center;
}

#headshot {
  padding: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 300px;
}

@media (max-width: 768px) {
  #headshot {
    width: 50%;
    display: flex;
    text-align: center;
  }
  .intro-1 {
    padding: 20px;
    margin: auto;
  }
}
