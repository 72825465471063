.bgimage {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/src/images/Website\ Cover.svg");
  background-size: cover;
  position: relative;
}
.hero_title {
  font-size: 4.5rem;
}
.hero_desc {
  font-size: 2rem;
}
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(165, 158, 158);
}
