.card {
  max-width: 100%;
}
.servicesText.card {
  width: 100%;
  padding: 10px;
  cursor: pointer;
}
.servicesIcon {
  font-size: 24px;
  text-align: center;
  width: 100%;
}
.card-title {
  text-align: center;
}
.card:hover .servicesIcon {
  color: red;
}
.servicesText:hover {
  border: 1px solid red;
}
